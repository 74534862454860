import Polyglot from 'node-polyglot'

// English is our source language so we do not need to import the translated
// strings in production.
// We do want them around during development so that we can get accurate warnings
// about which strings are missing translations.
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const messages = require('../../locales/chatterbug.json')
  const formatMessages = (messages) =>
    Object.keys(messages).reduce(
      (newMessages, key) => ({ ...newMessages, [key]: messages[key].message }),
      {}
    )
  global.Polyglot = new Polyglot({
    phrases: formatMessages(messages),
    locale: 'en',
  })
} else {
  // run in prodution
  global.Polyglot = new Polyglot({
    onMissingKey: Polyglot.transformPhrase, // don't warn us.
    locale: 'en',
  })
}
